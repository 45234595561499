var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pricing my-8 my-md-16"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-card-title',{staticClass:"pricing__title text-uppercase white--text mx-auto",class:_vm.site == 'creators'
          ? 'pricing__title--creators mb-4 mb-md-8'
          : 'pricing__title--standard  mb-8 mb-md-16'},[_vm._v(_vm._s(_vm.title))])],1),_c('v-row',{staticClass:"ml-4 mr-n4 ml-sm-16 mr-sm-n16 ml-md-16 mr-md-n16 ml-lg-0 mr-lg-0 ml-xl-16 mr-xl-n16",attrs:{"no-gutters":"","justify":"center"}},_vm._l((_vm.columns),function(col){return _c('v-col',{key:col.index,staticClass:"ml-0 mr-0 ml-sm-16 mr-sm-n16 ml-md-0 mr-md-0",attrs:{"cols":"12","md":"6","lg":"3"}},[_c('v-row',{staticClass:"ml-0 mr-0 ml-lg-16 mr-lg-n16 ml-xl-0 mr-xl-0",attrs:{"no-gutters":"","justify":"start"}},[_c('v-dialog',{key:col.index,attrs:{"transition":"fade-transition","max-width":"800","content-class":"elevation-0"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"pricing__image d-none d-xl-flex",attrs:{"max-width":"300","src":col.image,"lazy-src":col.imageLazy},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on)),_c('v-img',_vm._g(_vm._b({staticClass:"pricing__image d-xl-none",attrs:{"max-width":"200","src":col.image,"lazy-src":col.imageLazy},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},'v-img',attrs,false),on))]}}],null,true),model:{value:(_vm.dialog[col.index]),callback:function ($$v) {_vm.$set(_vm.dialog, col.index, $$v)},expression:"dialog[col.index]"}},[_c('v-card',{attrs:{"color":"transparent"}},[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"white","fab":"","text":""},on:{"click":function($event){return _vm.$set(_vm.dialog, col.index, false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-img',{staticClass:"mx-auto",attrs:{"max-width":"800","src":col.image,"lazy-src":col.imageLazy},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)})],1)],1)],1),_c('v-row',{staticClass:"ml-0 mr-0 ml-lg-16 mr-lg-n16 ml-xl-0 mr-xl-0",attrs:{"no-gutters":"","justify":"start"}},[_c('v-card-title',{staticClass:"pricing__subtitle text-uppercase text-no-wrap my-2 ml-n4",class:_vm.site == 'creators'
              ? 'pricing__subtitle--creators mt-3'
              : 'pricing__subtitle--standard mt-3'},[_vm._v(" "+_vm._s(col.title)+" ")])],1),_vm._l((col.items),function(item){return _c('div',{key:item.index},[_c('v-row',{staticClass:"ml-0 mr-0 ml-lg-16 mr-lg-n16 ml-xl-0 mr-xl-0",attrs:{"no-gutters":"","justify":"start"}},[_c('v-col',{attrs:{"cols":"4","sm":"3","md":"4","lg":"5"}},[_c('p',{staticClass:"pricing__item",class:_vm.site == 'creators'
                  ? 'pricing__item--creators'
                  : 'pricing__item--standard'},[_vm._v(" "+_vm._s(item.item)+": ")])]),_c('v-col',{attrs:{"cols":"8","sm":"9","md":"8","lg":"7"}},_vm._l((item.prices),function(price){return _c('p',{key:price.index,staticClass:"pricing__price"},[_vm._v(" "+_vm._s(price)+" ")])}),0)],1),_c('v-row',{staticClass:"ml-0 mr-0 ml-lg-16 mr-lg-n16 ml-xl-0 mr-xl-0",attrs:{"no-gutters":""}},[_c('p',{staticClass:"pricing__note mt-n4"},[_vm._v(_vm._s(item.note))])]),_c('v-row',{staticClass:"ml-0 mr-0 ml-lg-16 mr-lg-n16 ml-xl-0 mr-xl-0",attrs:{"no-gutters":""}},[_c('ul',{staticClass:"pricing__list ml-2 mt-n4"},_vm._l((item.list),function(list){return _c('li',{key:list.index},[_vm._v(" "+_vm._s(list)+" ")])}),0)])],1)})],2)}),1),_c('v-row',{attrs:{"no-gutters":"","justify":"center","justify-md":"end"}},_vm._l((_vm.buttons),function(button){return _c('Button',{key:button.index,attrs:{"name":button.name,"to":button.to}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }